"use client";

//
// ApplicationLayout.tsx
//

import GlobalStyle from "@components/GlobalStyle";
import SingleProjectInfo from "@components/Projects/SingleProjectInfo";
import Profile from "@components/User/Profile";
import { StateContext } from "@context/StateContext";
import {
  useGetCurrentProjectId,
  useGetProjectStorageInfo,
  useGetUserProjects,
} from "@custom-hooks/Projects";
import { useUploadDatabase } from "@custom-hooks/useApi";
import { useCustomRouter } from "@custom-hooks/useCustomRouter";
import { useGetUserPlanInfo, useGetUserSession } from "@custom-hooks/User";
import { Projects } from "@data-types/projects-types";
import Feedback from "@generic-components/Feedback";
import OpenDialog from "@generic-components/OpenDialog";
import SnackNotification from "@generic-components/SnackNotification";
import * as Headless from "@headlessui/react";
import AppNavbar from "@layouts/ApplicationLayout/AppNavbar";
import { CircleBrandMark } from "@layouts/ApplicationLayout/CircleBrandMark";
import { FullBrandMark } from "@layouts/ApplicationLayout/FullBrandMark";
import { UpgradeDialog } from "@layouts/ApplicationLayout/UpgradeDialog";
import { UpgradingInfraBanner } from "@layouts/ApplicationLayout/UpgradingInfraBanner";
import { UserBadge } from "@layouts/ApplicationLayout/UserBadge";
import { useGetSidebarTree } from "@layouts/lib/useGetSidebarTree";
import BoltIcon from "@layouts/svg-icon/bolt-icon.svg";
import CommunityIcon from "@layouts/svg-icon/community-icon.svg";
import ConnectIcon from "@layouts/svg-icon/connect-icon.svg";
import LogoutIcon from "@layouts/svg-icon/logout-icon.svg";
import MessageSquareTypingIcon from "@layouts/svg-icon/message-square-typing-icon.svg";
import MobileCloseIcon from "@layouts/svg-icon/mobile-close-icon.svg";
import ToggleSidebarState from "@layouts/svg-icon/toggle-sidebar-state-icon.svg";
import UserProfileCircleIcon from "@layouts/svg-icon/user-profile-circle-icon.svg";
import RocketBadge from "@layouts/svg-illustration/rocket-badge.svg";
import {
  DecorationBottomLeft,
  DecorationBottomRight,
  DecorationTopLeft,
  DecorationTopRight,
} from "@layouts/svg-illustration/sing-in-decoration";
import Wave1 from "@layouts/svg-illustration/wave-1.svg";
import { signOut } from "@lib/client-side";
import { generateRandomId } from "@lib/iso-utils";
import CssBaseline from "@mui/material/CssBaseline";
import { Button } from "@tw-components/ui/button";
import { NavbarItem } from "@tw-components/ui/navbar";
import { ProgressBar } from "@tw-components/ui/progress-bar";
import {
  Sidebar,
  SidebarBody,
  SidebarDivider,
  SidebarFooter,
  SidebarHeader,
  SidebarItem,
  SidebarSection,
} from "@tw-components/ui/sidebar";
import { SidebarLayout } from "@tw-components/ui/sidebar-layout";
import clsx from "clsx";
import Link from "next/link";
import React, {
  Fragment,
  MutableRefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

const UPGRADING_INFRA = process.env.NEXT_PUBLIC_UPGRADING_INFRA;

export function ApplicationLayout({ children }: { children: React.ReactNode }) {
  // Extracts the current path with custom router hook
  const { router, asPath, pathname } = useCustomRouter();

  // Parse pathname to extract actual slug
  const actualSlug = pathname?.split("/").at(-1);
  // Parses query parameters from the URL path to check for "profile"
  const queryParams = asPath && new URLSearchParams(asPath.split("?")[1]);
  const profile = queryParams && queryParams.get("profile");

  // Get session
  const {
    data: session,
    hasData: availableUserInfo,
    isLoading: loadingUser,
    isError: errorUser,
  } = useGetUserSession();

  // Show the layout only when the user info are available
  const [showLayout, setShowLayout] = useState(false);

  // If the user has never completed the onboarding or has zero project redirect to onboarding flow
  useEffect(() => {
    if (availableUserInfo && !loadingUser && !errorUser) {
      // Check if the onboarding process is completed
      const onboardingCompletedAt =
        session?.user?.attributes?.onboarding?.completedAt;

      // Check if the user has any authorized projects
      const hasProjects = Boolean(
        session?.authorizations?.projects &&
          Object.keys(session.authorizations.projects).length
      );

      // Redirect the user to the onboarding setup page if:
      // - No projects are authorized
      // - Onboarding is not completed
      // if (!onboardingCompletedAt) {
      if (!hasProjects && !onboardingCompletedAt) {
        router?.push("/onboarding/setup-organization");
      } else {
        setTimeout(() => {
          setShowLayout(true);
        }, 500);
      }
    }
  }, [session, availableUserInfo, router, loadingUser, errorUser]);

  // Get user plan
  const { data: planInfo, hasData: availablePlanInfo } = useGetUserPlanInfo();
  const projectPlan = availablePlanInfo && planInfo!.plan;

  // Fetch users projects
  const { data: projects, hasData: availableProjects } = useGetUserProjects();

  // Extract projects with at least one node
  const filteredProjectsWithNodes = availableProjects
    ? (projects as Projects).filter((project) => project.nodes_count > 0)
    : [];

  // Extract projects with at least one node in creation
  const filteredProjectsWithNodesInCreation = availableProjects
    ? (projects as Projects).filter((project) => project.creating_count > 0)
    : [];

  // Get acutal project id
  const projectId = useGetCurrentProjectId();

  //handle redirect based on project id and nodes count
  useEffect(() => {
    if (availableProjects && router) {
      //if projectId is false means that it is not a project of the current user. so redirect to 404 page
      if (projectId === false) {
        router.replace(`/not-found`);
      } else if (projectId) {
        //if projectId exists but the number of node is zero, redirect the user to the ndoes page
        projects!.forEach((project) => {
          if (project.id == projectId && project.nodes_count === 0) {
            if (actualSlug !== "nodes" && actualSlug !== "") {
              router.push(`/projects/${projectId}/nodes`);
            }
          }
        });
      }
    }
  }, [availableProjects, projectId, actualSlug, projects, router]);

  // Check if we are inside a project
  let projectIdToUse: string = "";
  if (projectId) {
    projectIdToUse = projectId;
  } else {
    if (availableProjects) {
      projectIdToUse = projects![0].id;
    }
  }
  // Fetch storage info for current project id
  const { data: storageInfo, hasData: availableStorageInfo } =
    useGetProjectStorageInfo(projectIdToUse);

  // Determine storage usage and availability
  const availableStorage = availableStorageInfo
    ? storageInfo?.availableStorage
    : "";

  const usedStorage = availableStorageInfo ? storageInfo?.usedStorage : "";
  const storagePercentage = availableStorageInfo
    ? storageInfo?.usedStoragePercentage
    : 0;
  const warningStorage = availableStorageInfo ? storageInfo?.warning : false;

  // Get state context
  const { setTabId } = useContext(StateContext);
  useEffect(() => {
    const tabId = "sqlitecloud-tab-" + generateRandomId();
    setTabId(tabId);
  }, [setTabId]);

  // Generates the sidebar tree based on whether a project is selected or not
  const sidebarItems = useGetSidebarTree();
  /**
   * `collapseSidebar` state, is a boolean that
   * controls the sidebar's appearance in desktop view. If true, only sidebar
   * icons will be shown, hiding the labels or extra content.
   */
  let [collapseSidebar, setCollapseSidebar] = useState(false);
  const handleCollapseSidebar = () => {
    setCollapseSidebar((prevState) => !prevState);
  };

  /**
   * `showSidebar` state, is a boolean that
   * controls the sidebar's appearance in mobile view.
   */
  let [showMobileSidebar, setShowMobileSidebar] = useState(false);

  // `showUpgradeDialog` state, is a boolean that controls the upgrade banner visility.
  let [showUpgradeDialog, setShowUpgradeDialog] = useState(false);
  const handleShowUpgradeDialog = () => {
    setShowUpgradeDialog((prevState) => !prevState);
  };

  // Reference for the connection dialog box
  const connectProjectDialogRef = useRef<MutableRefObject<any | null>>(null);

  // Reference for the profile dialog box
  const profileRefDialog = useRef<MutableRefObject<any | null>>(null);

  // Reference for the profile dialog box
  const updateInfraRefDialog = useRef<MutableRefObject<any | null>>(null);

  // Reference for the feedback dialog box
  const feedbackRef = useRef<MutableRefObject<any | null>>(null);

  // State to track whether to auto-open the profile dialog
  const [autoOpenProfile, setAutoOpenProfile] = useState(false);

  // Sets autoOpenProfile to true if the "profile" query parameter exists
  useEffect(() => {
    if (profile) {
      setAutoOpenProfile(true);
    }
  }, [profile]);

  // Opens the profile dialog when `autoOpenProfile` is true and user loading is complete
  useEffect(() => {
    if (autoOpenProfile && !loadingUser) {
      // @ts-expect-error OpenDialog method not typed for TypeScript
      profileRefDialog.current?.open();
    }
  }, [autoOpenProfile, loadingUser]);

  // Handling of upload and download

  //helper function to filter download and upload queue based on projectId
  function filterMapBasedOnProjectId(map: any, id: any) {
    let filteredItems = new Map();
    map.forEach((value: any, key: any) => {
      if (value.projectId === id) {
        filteredItems.set(key, value);
      }
    });
    return filteredItems;
  }

  //upload databases
  const { uploadQueue, uploadDb } = useUploadDatabase();
  const uploadOpts: any = {
    uploadQueue: projectId
      ? filterMapBasedOnProjectId(uploadQueue, projectId)
      : uploadQueue,
    uploadDb,
  };

  // Show button that opens the upgrading infrastructure dialog based on env variable value
  const upgradingInfra =
    UPGRADING_INFRA && UPGRADING_INFRA.toLowerCase() === "true";

  // Controls the visibility of the authentication layout by adding a "hidden" class
  // once the main layout is ready (indicated by showLayout). This triggers a smooth
  // opacity transition to the Sidebar layout. The 200ms delay aligns with the transition effect.
  const [hideAuth, setHideAuth] = useState(false);
  useEffect(() => {
    if (showLayout) {
      setTimeout(() => {
        setHideAuth(true);
      }, 200);
    }
  }, [showLayout]);

  // Manages the open/closed state of sidebar subitems.
  // Initializes as an empty object, which will later hold boolean values
  // for each sidebar item's unique key (slug) to track its open/closed status.
  // If a valid category (without dynamic segments) is detected in the URL path,
  // its corresponding sidebar item will be set to open by default.
  const [openSubitems, setOpenSubitems] = useState<{
    [key: string]: boolean;
  }>(() => {
    let open: {
      [key: string]: boolean;
    } = {};
    const actualCategory = pathname?.split("/").at(-2);
    if (actualCategory && !actualCategory.includes("[")) {
      open[actualCategory] = true;
    }
    return open;
  });

  // Toggles the open/closed state of a specific sidebar subitem by its unique key (slug).
  // When collapseSidebar is true, it will ensure the item is open. Otherwise, it toggles
  // the item's state based on its current status (open if closed, close if open).
  const handleOpenSubitem = (slug: string) => {
    // Create a shallow clone of the current openSubitems state to avoid direct mutation
    let newOpenSubitems = structuredClone(openSubitems);
    if (collapseSidebar) {
      newOpenSubitems[slug] = true;
    } else {
      newOpenSubitems[slug] = !newOpenSubitems[slug];
    }
    setOpenSubitems(newOpenSubitems);
    setCollapseSidebar(false);
  };

  // Render condition
  const showUpgradeCallToAction =
    !collapseSidebar &&
    availablePlanInfo &&
    availableStorageInfo &&
    projectPlan !== "SQLite Cloud Dev";

  // Render component
  return (
    <>
      <CssBaseline />
      <GlobalStyle />
      <div
        className={clsx(
          "tw-transition-opacity tw-duration-500 tw-ease-in-out",
          showLayout ? "tw-pointer-events-none tw-opacity-0" : "tw-opacity-100",
          hideAuth ? "tw-hidden" : ""
        )}
      >
        <div className="tw-relative tw-h-screen tw-overflow-hidden">
          <div className="tw-absolute tw-left-6 tw-top-6 tw-z-[51]">
            <CircleBrandMark />
          </div>
          <div className="tw-relative tw-z-50 tw-flex tw-h-full tw-items-center tw-justify-center tw-overflow-auto tw-p-6">
            <div className="tw-max-w-[22.875rem]">
              <FullBrandMark />
            </div>
          </div>
          <DecorationTopLeft />
          <DecorationBottomLeft />
          <DecorationTopRight />
          <DecorationBottomRight />
        </div>
      </div>
      <div
        className={clsx(
          "tw-h-full tw-max-h-full",
          "tw-transition-opacity tw-duration-500 tw-ease-in-out",
          hideAuth ? "tw-visible tw-opacity-100" : "tw-invisible tw-opacity-0"
        )}
      >
        {showLayout && (
          <SidebarLayout
            showMobileSidebar={showMobileSidebar}
            setShowMobileSidebar={setShowMobileSidebar}
            collapseSidebar={collapseSidebar}
            navbar={
              <AppNavbar
                setShowMobileSidebar={setShowMobileSidebar}
                feedbackRef={feedbackRef}
                updateInfraRefDialog={updateInfraRefDialog}
                profileRefDialog={profileRefDialog}
              />
            }
            sidebar={
              <Sidebar>
                <SidebarHeader>
                  <div
                    className={clsx(
                      "tw-flex tw-flex-row tw-items-center tw-py-3",
                      "tw-cursor-default",
                      collapseSidebar
                        ? "tw-justify-center"
                        : "tw-justify-between tw-transition-colors tw-duration-150"
                    )}
                  >
                    <Link href="/">
                      <CircleBrandMark size={collapseSidebar ? 0 : 44} />
                    </Link>
                    <Button
                      onClick={handleCollapseSidebar}
                      size="small"
                      variant="tertiary"
                      icon={<ToggleSidebarState />}
                      className="tw-hidden lg:tw-flex"
                    />
                    <div className="tw-block lg:tw-hidden">
                      <Headless.CloseButton
                        as={NavbarItem}
                        aria-label="Close navigation"
                      >
                        <MobileCloseIcon className="!tw-h-10 !tw-w-10" />
                      </Headless.CloseButton>
                    </div>
                  </div>
                </SidebarHeader>

                <SidebarBody
                  className={clsx(collapseSidebar ? "tw-items-center" : "")}
                >
                  {/* Navigation Items */}
                  <SidebarSection className="lg:!tw-hidden">
                    <UserBadge />
                  </SidebarSection>
                  {/* Navigation Items */}
                  <SidebarSection>
                    {sidebarItems?.pages?.map((page, i) => {
                      if (page) {
                        return (
                          <Fragment key={page.slug}>
                            <SidebarItem
                              key={page.slug}
                              current={actualSlug === page.slug}
                              href={page.href || undefined}
                              collapsed={collapseSidebar}
                              disabled={page.disabled}
                              label={page.label}
                              icon={page.icon}
                              experimental={page.experimental}
                              hasSubItems={!!page.children && !collapseSidebar}
                              onClick={
                                page.children
                                  ? () => {
                                      handleOpenSubitem(page.slug);
                                    }
                                  : undefined
                              }
                              expanded={
                                !page.disabled &&
                                page.children &&
                                openSubitems &&
                                openSubitems[page.slug] == true
                                  ? true
                                  : undefined
                              }
                            />
                            <SidebarSection
                              className={clsx(
                                collapseSidebar ||
                                  !page.children ||
                                  page.disabled
                                  ? "tw-hidden"
                                  : "",
                                "tw-overflow-hidden tw-transition-all tw-duration-500 tw-ease-in-out",
                                openSubitems[page.slug]
                                  ? "tw-max-h-screen"
                                  : "tw-max-h-0"
                              )}
                            >
                              {page.children?.map((page, i) => {
                                if (page) {
                                  return (
                                    <SidebarItem
                                      className={clsx(
                                        collapseSidebar
                                          ? "tw-pointer-events-none"
                                          : ""
                                      )}
                                      variant="inner"
                                      key={page.slug}
                                      current={
                                        pathname?.split("/").at(-1) ===
                                          page.slug && !collapseSidebar
                                      }
                                      href={page.href || undefined}
                                      collapsed={collapseSidebar}
                                      disabled={false}
                                      label={page.label}
                                      icon={page.icon}
                                      experimental={page.experimental}
                                    />
                                  );
                                }
                              })}
                            </SidebarSection>
                          </Fragment>
                        );
                      }
                    })}
                  </SidebarSection>
                  <SidebarDivider className="lg:tw-hidden" />
                  <SidebarSection className="lg:!tw-hidden">
                    <div className="tw-dark:tw-text-text-subTitle-dark tw-text-12px-regular tw-uppercase tw-text-text-subTitle-light">
                      Support
                    </div>
                    <SidebarItem
                      key={"community"}
                      href={"https://github.com/orgs/sqlitecloud/discussions/"}
                      target="_blank"
                      label={"Community"}
                      icon={<CommunityIcon />}
                    />
                    <SidebarItem
                      key={"contact-us"}
                      onClick={() => {
                        if (feedbackRef && feedbackRef.current) {
                          // @ts-expect-error OpenDialog not writter for typescript
                          feedbackRef.current.open();
                        }
                      }}
                      label={"Contact Us"}
                      icon={<MessageSquareTypingIcon />}
                    />
                  </SidebarSection>
                  <SidebarDivider className=" lg:tw-hidden" />
                  <SidebarSection className="lg:!tw-hidden">
                    <div className="tw-dark:tw-text-text-subTitle-dark tw-text-12px-regular tw-uppercase tw-text-text-subTitle-light">
                      USER SETTING
                    </div>
                    <SidebarItem
                      key={"profile"}
                      onClick={() => {
                        // @ts-expect-error OpenDialog not writter for typescript
                        profileRefDialog.current?.open();
                      }}
                      label={"Profile"}
                      icon={<UserProfileCircleIcon />}
                    />
                    <SidebarItem
                      key={"sign-out"}
                      onClick={async () => {
                        signOut();
                      }}
                      label={"Sign out"}
                      icon={<LogoutIcon />}
                    />
                  </SidebarSection>
                </SidebarBody>

                <SidebarFooter>
                  {showUpgradeCallToAction && (
                    <div className=" tw-dark:tw-border-brandBlues-ice-dark tw-relative tw-mb-8 tw-flex tw-h-[170px] tw-flex-col tw-gap-3 tw-overflow-hidden tw-rounded-lg tw-border tw-border-brandBlues-ice-light tw-p-4">
                      <Wave1 className="tw-pointer-events-none tw-absolute tw-bottom-0 tw-left-0 -tw-z-10" />
                      <div className="tw-flex tw-flex-col tw-gap-[0.375rem]">
                        <RocketBadge />
                        <p className=" tw-dark:tw-text-brandBlues-darkBlue-dark tw-text-12px-regular tw-text-brandBlues-darkBlue-light">
                          Your currently using <br />
                          {usedStorage} / {availableStorage}, <br /> upgrade to
                          SQLite Cloud Pro.
                        </p>
                      </div>
                      <ProgressBar
                        value={storagePercentage!}
                        variant={warningStorage ? "error" : "base"}
                      />
                      <Button
                        onClick={handleShowUpgradeDialog}
                        size="small"
                        variant="primary"
                        icon={<BoltIcon />}
                        label={collapseSidebar ? "" : "Upgrade To Pro"}
                      />
                    </div>
                  )}
                  {filteredProjectsWithNodes.length > 0 ? (
                    <Button
                      size={collapseSidebar ? "small" : "large"}
                      variant="secondary"
                      icon={<ConnectIcon />}
                      label={collapseSidebar ? "" : "Connect"}
                      className={clsx(collapseSidebar ? "tw-self-center" : "")}
                      onClick={(e: React.MouseEvent) => {
                        e.preventDefault();
                        e.stopPropagation();
                        // @ts-expect-error OpenDialog not writter for typescript
                        connectProjectDialogRef.current?.open();
                      }}
                    />
                  ) : collapseSidebar ? (
                    <Button
                      size={"small"}
                      variant="primary"
                      icon={<ConnectIcon />}
                      className={"tw-self-center"}
                      disabled={true}
                    />
                  ) : (
                    <div className=" tw-dark:tw-border-brandBlues-ice-dark tw-relative tw-flex tw-flex-col tw-gap-3 tw-overflow-hidden tw-rounded-lg tw-border tw-border-brandBlues-ice-light tw-p-4">
                      <p className="tw-text-16px-light tw-text-brandBlues-brandSecondary-light dark:tw-text-brandBlues-brandSecondary-dark">
                        Get Started!
                      </p>
                      <p className="tw-text-12px-regular tw-text-brandBlues-darkBlue-light dark:tw-text-brandBlues-darkBlue-dark">
                        {filteredProjectsWithNodesInCreation.length > 0
                          ? "When your project is ready, use this button to get your connection string."
                          : "Create your first project. When it's ready, use this button to get your connection string."}
                      </p>
                      <Button
                        size={"large"}
                        variant="primary"
                        icon={<ConnectIcon />}
                        label={collapseSidebar ? "" : "Connect"}
                        disabled={true}
                      />
                    </div>
                  )}
                </SidebarFooter>
              </Sidebar>
            }
          >
            {React.Children.map(children, (child) =>
              React.isValidElement(child)
                ? // @ts-expect-error upload was developed before using typescript
                  React.cloneElement(child, { uploadOpts })
                : child
            )}
          </SidebarLayout>
        )}
      </div>
      {/* Connection Project Dialogs */}
      {/* @ts-expect-error OpenDialog not writter for typescript */}
      <OpenDialog
        ref={connectProjectDialogRef}
        size="small-modal"
        style="none"
        decorationVariant={2}
      >
        <SingleProjectInfo projectId={projectId} />
      </OpenDialog>
      {/* Upgrade Dialog Box */}
      <UpgradeDialog
        isOpen={showUpgradeDialog}
        setIsOpen={setShowUpgradeDialog}
      />
      {/* Profile Dialog Box */}
      {/* @ts-expect-error OpenDialog method not typed for TypeScript*/}
      <OpenDialog ref={profileRefDialog} style="none" size="small-modal">
        <Profile />
      </OpenDialog>
      {/* Feedback Dialog Box */}
      <Feedback feedbackRef={feedbackRef} />
      {/* Upgrading Infrastracture Dialog Box */}
      <>
        {upgradingInfra && (
          <>
            {/* @ts-expect-error OpenDialog method not typed for TypeScript*/}
            <OpenDialog
              ref={updateInfraRefDialog}
              size="medium-modal"
              style="none"
              decorationVariant={0}
            >
              <UpgradingInfraBanner />
            </OpenDialog>
          </>
        )}
      </>
      <SnackNotification />
    </>
  );
}
