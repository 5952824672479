//
// index.tsx - Plan Info related functionality
//

import { FetchHookResult } from "@data-types/generic-hook-type";
import { ProjectPlan } from "@data-types/plan-info-types";
import { Session } from "@data-types/user-types";
import { renderAnalyzer, swrFetcher } from "@lib/client-side";
import { safelyParseJSON } from "@lib/iso-utils";
import useSWR from "swr";

// Utils

/**
 * Recursively parses the "attributes" field in an object, attempting to safely parse JSON strings.
 * If parsing fails, the original string is preserved.
 *
 * - If a key is named "attributes" and is a string, it tries to parse it as JSON.
 * - For nested objects, it recursively applies the same logic.
 *
 * @param obj - The input object whose attributes need parsing.
 * @returns The object with parsed attributes wherever applicable.
 */
function parseAttributes(obj: any): any {
  // Iterate through each key in the object
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (key === "attributes" && typeof obj[key] === "string") {
        // Parse the attributes string if it's a valid JSON
        obj[key] = safelyParseJSON(obj[key]);
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        // Recursively check nested objects
        obj[key] = parseAttributes(obj[key]);
      }
    }
  }

  return obj;
}

/**
 * Helper function to parse size description into individual CPU, RAM, and storage fields.
 *
 * @param {string} sizeDescription - The size description string, e.g., "0.5vCPU / 512MB / 5GB".
 * @returns {{ cpu: string; ram: string; storage: string }} Parsed fields for CPU, RAM, and storage.
 */
function parseSizeDescription(sizeDescription: string): {
  cpu: string;
  ram: string;
  storage: string;
} {
  const [cpu, ram, storage] = sizeDescription.split(" / ");
  return { cpu, ram, storage };
}

// Hooks

/**
 * Custom hook to fetch and process user plan information.
 *
 * This hook retrieves user plan data from the API, processes the plan description into separate
 * fields (CPU, RAM, and storage), and provides various states for managing display in the UI.
 *
 * @param {number} [refreshInterval] - Optional interval (in milliseconds) for refreshing project storage info data.
 *
 * @returns {FetchHookResult<ProjectPlan>} An object containing:
 *   - `data`: The processed plan information or `undefined` if no data is available.
 *   - `isLoading`: `true` if data is currently loading.
 *   - `isError`: Any error encountered during data fetching.
 *   - `isValidating`: `true` if the data is in the process of revalidating.
 *   - `showLoader`: `true` if a loading indicator should be shown.
 *   - `hasData`: `true` if there is plan data available.
 *   - `emptyData`: `true` if plan data is available but empty.
 *   - `mutate`: A function to manually revalidate and refresh the fetched data.
 */
export function useGetUserPlanInfo(
  refreshInterval?: number
): FetchHookResult<ProjectPlan> {
  const { data, error, isValidating, mutate } = useSWR(
    ["/api/plan", "useGetPlanInfo"],
    swrFetcher,
    {
      refreshInterval: refreshInterval ?? 0,
      revalidateOnFocus: false,
    }
  );

  // Analyze data to determine if it has content or is empty
  const { hasData, emptyData, showLoader } = renderAnalyzer(
    data,
    error,
    isValidating,
    "backend"
  );

  // Parse the plan information if data is available
  const planInfo: ProjectPlan | undefined = hasData
    ? {
        ...data.value[0],
        ...parseSizeDescription(data.value[0].size_description),
      }
    : undefined;

  return {
    data: planInfo,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
    hasData,
    emptyData,
    mutate,
  };
}

/**
 * Hook to fetch and manage the user's session data.
 *
 * This hook retrieves session details from the authentication API endpoint
 * and provides various states to simplify UI management.
 *
 * @param refreshInterval - (Optional) Interval in milliseconds to refresh session data automatically. Defaults to `0` (no auto-refresh).
 * @returns An object containing:
 *   - `data`: The session data or `undefined` if not available.
 *   - `isLoading`: `true` if the session data is currently loading.
 *   - `isError`: The error object if an error occurred during fetching.
 *   - `isValidating`: `true` if the data is being revalidated.
 *   - `showLoader`: `true` if a loader should be displayed (loading or validating state).
 *   - `hasData`: `true` if session data exists and is non-empty.
 *   - `emptyData`: `true` if session data exists but is empty.
 *   - `mutate`: A function to manually trigger revalidation and refresh session data.
 */
export function useGetUserSession(
  refreshInterval?: number
): FetchHookResult<Session> {
  // Use SWR to fetch session data with optional auto-refresh and caching
  const { data, error, isValidating, mutate } = useSWR(
    ["/api/auth/session", "useGetUserSession"],
    swrFetcher,
    {
      refreshInterval: refreshInterval ?? 0,
      revalidateOnFocus: false,
    }
  );

  // Analyze session data for loading, presence, or emptiness
  const { hasData, emptyData, showLoader } = renderAnalyzer(
    data,
    error,
    isValidating,
    "auth"
  );

  // If session data exists, attempt to parse the `attributes` field as JSON
  let result;
  if (hasData) {
    result = parseAttributes(data);
  }

  // Return all hook states and utilities
  return {
    data: result,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
    hasData,
    emptyData,
    mutate,
  };
}
