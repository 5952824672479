//
// projects-types.ts
//

import { Regions } from "./regions-types";

export enum Environment {
  Development = 0,
  Staging = 1,
  Production = 2,
}

export type Project = {
  admin_password: string;
  admin_username: string;
  creation_date: string;
  description: string;
  env: Environment;
  id: string;
  name: string;
  nodes_count: number;
  creating_count: number;
  project_address: string;
  regions: string; // e.g. [{"code":"us-west-1","description":"US West (N. California)"}]
  regionsList: Regions;
};

export type Projects = Project[];
