"use client";

//
// AppNavbar.tsx
//

import { Search } from "@components/Search/Search";
import Profile from "@components/User/Profile";
import { StateContext } from "@context/StateContext";
import { useGetCurrentProjectId } from "@custom-hooks/Projects";
import { useGetUserSession } from "@custom-hooks/User";
import { useGetProjectInfo } from "@custom-hooks/useApi";
import Feedback from "@generic-components/Feedback";
import OpenDialog from "@generic-components/OpenDialog";
import { CircleBrandMark } from "@layouts/ApplicationLayout/CircleBrandMark";
import { UpgradingInfraBanner } from "@layouts/ApplicationLayout/UpgradingInfraBanner";
import BinocularsIcon from "@layouts/svg-icon/binoculars-icon.svg";
import CommunityIcon from "@layouts/svg-icon/community-icon.svg";
import DownChevronIcon from "@layouts/svg-icon/down-chevron-icon.svg";
import LogoutIcon from "@layouts/svg-icon/logout-icon.svg";
import MessageSquareTypingIcon from "@layouts/svg-icon/message-square-typing-icon.svg";
import MobileHamburgerIcon from "@layouts/svg-icon/mobile-hamburger-icon.svg";
import QuestionMarkIcon from "@layouts/svg-icon/question-mark-icon.svg";
import RightChevronIcon from "@layouts/svg-icon/right-chevron-icon.svg";
import UserProfileCircleIcon from "@layouts/svg-icon/user-profile-circle-icon.svg";
import WarningIcon from "@layouts/svg-icon/warning-icon.svg";
import { signOut } from "@lib/client-side";
import { getInitials } from "@lib/iso-utils";
import { Avatar } from "@tw-components/ui/avatar";
import { Button } from "@tw-components/ui/button";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from "@tw-components/ui/dropdown";
import {
  Navbar,
  NavbarItem,
  NavbarSection,
  NavbarSpacer,
} from "@tw-components/ui/navbar";
import clsx from "clsx";
import Link from "next/link";
import { MutableRefObject, useContext } from "react";

const UPGRADING_INFRA = process.env.NEXT_PUBLIC_UPGRADING_INFRA;

function Breadcrumbs() {
  // Get user session
  const {
    data: session,
    isLoading: loadingSession,
    hasData: availableSession,
  } = useGetUserSession();

  // Extract organization name
  let firstOrganization;
  if (availableSession) {
    const organizations = session!.authorizations?.organizations;
    firstOrganization = Object.entries(organizations as object)[0][1];
  }

  // Get actual project Id
  const projectId = useGetCurrentProjectId();

  // Get actual project indfo
  const { name: projectName } = useGetProjectInfo();

  const insideAProject = projectId;

  // Render components
  return (
    <>
      {availableSession && !loadingSession && (
        <div
          className={clsx(
            loadingSession
              ? "tw-dark:tw-bg-interface-gray-dark tw-h-3 tw-min-w-[200px] tw-animate-pulse tw-rounded tw-bg-interface-gray-light"
              : "",
            "tw-text-14px-regular",
            "tw-flex tw-flex-row tw-items-center tw-gap-2 "
          )}
        >
          <span className="tw-dark:tw-text-text-body-dark tw-text-text-body-light">
            {firstOrganization.name}
          </span>
          {insideAProject && <RightChevronIcon />}
          {insideAProject && (
            <span className="tw-dark:tw-text-text-subTitle-dark tw-text-text-subTitle-light">
              {projectName}
            </span>
          )}
        </div>
      )}
    </>
  );
}

export default function AppNavbar({
  setShowMobileSidebar,
  feedbackRef,
  updateInfraRefDialog,
  profileRefDialog,
}: {
  setShowMobileSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  feedbackRef: MutableRefObject<any | null>;
  updateInfraRefDialog: MutableRefObject<any | null>;
  profileRefDialog: MutableRefObject<any | null>;
}) {
  // Get user session
  const {
    data: session,
    isLoading: loadingSession,
    hasData: availableSession,
  } = useGetUserSession();

  const userName = session?.user?.name ?? "";

  // Get simulation generic user from context
  const { simulateGenericUser } = useContext(StateContext);

  // Render condition
  const upgradingInfra =
    UPGRADING_INFRA && UPGRADING_INFRA.toLowerCase() === "true";

  // Render component
  return (
    <>
      {/* Profile Dialog Box */}
      {/* @ts-expect-error OpenDialog method not typed for TypeScript*/}
      <OpenDialog ref={profileRefDialog} style="none" size="small-modal">
        <Profile />
      </OpenDialog>
      {/* Feedback Dialog Box */}
      <Feedback feedbackRef={feedbackRef} />
      {/* Upgrading Infrastracture Dialog Box */}
      <>
        {upgradingInfra && (
          <>
            {/* @ts-expect-error OpenDialog method not typed for TypeScript*/}
            <OpenDialog
              ref={updateInfraRefDialog}
              size="medium-modal"
              style="none"
              decorationVariant={0}
            >
              <UpgradingInfraBanner />
            </OpenDialog>
          </>
        )}
      </>
      {/* Navigation Bar */}
      <Navbar>
        {/* Brand Mark and Desktop Breadcrumbs */}
        <div className="tw-flex tw-flex-col tw-items-start tw-gap-x-8 tw-gap-y-12 lg:tw-flex-row lg:tw-items-center">
          <div className="tw-block lg:tw-hidden">
            <Link href="/">
              <CircleBrandMark />
            </Link>
          </div>
          <div className="tw-hidden lg:tw-block">
            <Breadcrumbs />
          </div>
        </div>
        <NavbarSpacer />
        {/* NavBar items */}
        <NavbarSection>
          {upgradingInfra && (
            <Button
              className="tw-mx-2"
              onClick={() => {
                if (updateInfraRefDialog && updateInfraRefDialog.current) {
                  updateInfraRefDialog.current.open();
                }
              }}
              variant="tertiary"
              label="Upgrading"
              size="medium"
              icon={<WarningIcon />}
            />
          )}
          {!simulateGenericUser && (
            <Button
              variant="destructive"
              label="Experimental"
              size="medium"
              className="tw-pointer-events-none tw-mx-2"
              icon={<BinocularsIcon />}
            />
          )}
          <span className="tw-flex tw-items-center tw-justify-center tw-px-2">
            <Search
              style="only-icon"
              targetLink="_blank"
              relativeUrl={false}
              hiddenClass="opacity-0"
              searchSource="doc"
              modalInputPlaceholder="Search SQLite Cloud"
            />
          </span>
          {/* Support Dropdown menu */}
          <Dropdown>
            <DropdownButton
              as={NavbarItem}
              className="max-lg:tw-hidden"
              aria-label="Support Menu"
            >
              <QuestionMarkIcon />
              <DownChevronIcon />
            </DropdownButton>
            <DropdownMenu className="min-w-64" anchor="bottom end">
              <DropdownItem
                href={"https://github.com/orgs/sqlitecloud/discussions/"}
                target="_blank"
              >
                <CommunityIcon />
                <DropdownLabel>Community</DropdownLabel>
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  if (feedbackRef && feedbackRef.current) {
                    feedbackRef.current.open();
                  }
                }}
              >
                <MessageSquareTypingIcon />
                <DropdownLabel>Contact Us</DropdownLabel>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>

          {/* Profile Dropdown menu */}
          <Dropdown>
            <DropdownButton
              as={NavbarItem}
              className="max-lg:tw-hidden"
              aria-label="Profile Menu"
            >
              <Avatar initials={getInitials(userName)} />
            </DropdownButton>
            <DropdownMenu className="min-w-64" anchor="bottom end">
              <DropdownItem
                onClick={() => {
                  profileRefDialog.current?.open();
                }}
              >
                <UserProfileCircleIcon />
                <DropdownLabel>Profile</DropdownLabel>
              </DropdownItem>
              <DropdownItem
                onClick={async () => {
                  signOut();
                }}
              >
                <LogoutIcon />
                <DropdownLabel>Sign out</DropdownLabel>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <NavbarItem
            className="tw-block lg:tw-hidden"
            onClick={() => {
              setShowMobileSidebar(true);
            }}
          >
            <MobileHamburgerIcon className="!tw-h-10 !tw-w-10" />
          </NavbarItem>
        </NavbarSection>
      </Navbar>
      {/* Breadcrumbs mobile */}
      <div className="tw-mb-11 tw-mt-9 tw-block tw-px-4 lg:tw-hidden">
        <Breadcrumbs />
      </div>
    </>
  );
}
